<template>
  <div>
    {{ messages }}
  </div>
</template>

<script>

export default {
  data() {
    return {
      messages: [],
    };
  },
  mounted() {
    // Pusher.logToConsole = true;
    var pusher = new Pusher("c17b5afff3d12d8c3bbc", {
      cluster: "ap1",
      wsHost: "https://api2.buzzprivilegewifi.com",
      wsPort: 6001,
      enabledTransports: ["ws"],
      forceTLS: false,
      authEndpoint: "https://api2.buzzprivilegewifi.com/broadcasting/auth",
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      },
    });

    const channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data) => {
      console.log(data.message);
      this. updateData(data);

    });
  },
  methods: {
    updateData(data) {
      console.log("loaddata :>> ");
      this.messages.push(data.message)
      
    },
  },
};
</script>

<style lang="scss" scoped></style>
